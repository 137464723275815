export const CLIENTS_SUCCESS = 'CLIENTS_SUCCESS';
export const PAY_RATE_SUCCESS = 'PAY_RATE_SUCCESS';
export const JOB_ACCESS_SUCCESS = 'JOB_ACCESS_SUCCESS';
export const WORK_WEEK_PAY_RATE_SUCCESS = 'WORK_WEEK_PAY_RATE_SUCCESS';
export const CONTRACT_TYPES = 'CONTRACT_TYPES';
export const JOB_DIVISIONS = 'JOB_DIVISIONS';

export const GetClientsSuccess = (payload: any) => ({
  type: CLIENTS_SUCCESS,
  payload,
});

export const GetPayRateSuccess = (payload: any) => ({
  type: PAY_RATE_SUCCESS,
  payload,
});
export const GetJobAccessSuccess = (payload: any) => ({
  type: JOB_ACCESS_SUCCESS,
  payload,
});

export const SetWorkWeekPayRates = (payload: any) => ({
  type: WORK_WEEK_PAY_RATE_SUCCESS,
  payload,
});

export const SetContractTypes = (payload: any) => ({
  type: CONTRACT_TYPES,
  payload,
});

export const SetJobsDivisions = (payload: any) => ({
  type: JOB_DIVISIONS,
  payload,
});
