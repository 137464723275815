import { createTheme } from '@mui/material';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 360,
      sm: 640,
      md: 768,
      lg: 1368,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: 'Assistant',
  },
  palette: {
    primary: {
      light: '#81377A',
      main: '#0B406B',
      dark: '#36213E',
    },
    secondary: {
      main: '#0096FB',
      dark: 'var(--secondary_dark)',
    },
    error: {
      main: '#BC0E33',
    },
    warning: {
      main: '#D39F06',
    },
    success: {
      main: '#6CBA60',
    },
    text: {
      primary: '#2E282A',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          zIndex: 1000,
          background: 'var(--white)',
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'var(--disabled)',
        },
        root: {
          borderRadius: 8,
          '&.Mui-disabled': {
            background: 'var(--disabled_25)',
          },
        },
        adornedStart: {
          color: 'var(--disabled)',
        },
        input: {
          color: 'var(--black)',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline,:hover .MuiOutlinedInput-notchedOutline':
              {
                borderColor: 'var(--blue_25)',
              },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          lineHeight: '18px',
          marginBottom: 6,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontSize: 16,
          cursor: 'pointer',
          '&.menu': {
            color: '#FFFFFF',
          },
          '&.active': {
            backgroundColor: '#ffffff',
            color: '#2E282A',
            fontWeight: 700,
            '.menu-icon path': {
              fill: '#2E282A',
            },
          },
          '&.sideListItem': {
            padding: 8,
            marginBottom: 8,
            '&.standard': {
              color: 'var(--primary_dark)',
              fontWeight: 700,
              '&.selected': {
                backgroundColor: 'var(--success_10)',
              },
            },
            '&.secondary': {
              color: 'var(--primary_dark)',
              paddingLeft: 32,
              '&.selected': {
                backgroundColor: 'var(--primary_25)',
                fontWeight: 700,
              },
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: 'var(--white)',
            backgroundColor: 'var(--primary_main)',
            '&:hover': {
              backgroundColor: 'var(--primary_main)',
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 'inherit',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          flex: 1,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          minHeight: 36,
          height: 'auto',
          borderRadius: 5,
          fontSize: 14,
          fontWeight: 600,
          lineHeight: '18px',
          textTransform: 'capitalize',
        },
        outlinedPrimary: {
          border: '1px solid var(--secondary_dark)',
          color: 'var(--secondary_dark)',
        },
        outlinedError: {
          border: '1px solid var(--error)',
          color: 'var(--error)',
        },
        text: {
          color: '#0096FB',
        },
        contained: {
          ':hover': {
            background: 'var(--secondary_dark)',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'var(--primary_main)',
          '.MuiSvgIcon-root path': {
            borderWidth: 1.25,
            borderRadius: 2,
            '&:hover': {
              boxShadow: '0px 0px 4px 3px rgba(11, 64, 107, 0.1)',
            },
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          zIndex: 999,
          border: 'none',
          '&.navigation-drawer': {
            backgroundColor: 'var(--primary_main)',
          },
          '&.custom-drawer': {
            border: '1px solid',
            borderColor: 'var(--stroke_light)',
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)',
            borderRadius: 8,
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          marginLeft: 20,
        },
        badge: {
          '&.limit-badge': {
            width: 23,
            height: 18,
            background: 'var(--primary_10)',
            color: 'var(--black)',
            marginRight: 10,
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          background: 'var(--ivory_25)',
        },
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {},
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: 44,
          borderBottom: '2px solid rgba(194, 193, 201, 0.25)',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 600,
        },
        root: {
          '&.cell-bold': {
            fontSize: 14,
            fontWeight: 600,
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          fontSize: 14,
          fontWeight: 600,
        },
        selectRoot: {
          fontSize: 14,
          fontWeight: 600,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          boxShadow: 'var(--header_drop)',
          borderRadius: 4,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'var(--black_75)',
          textTransform: 'capitalize',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          fontSize: 16,
          '&.dark': {
            background: 'var(--ivory_50)',
            border: '1px solid var(--disabled_25)',
            borderRadius: 4,
            '.MuiTabs-indicator': {
              backgroundColor: 'unset',
            },
            '.Mui-selected': {
              color: 'var(--black)',
              fontWeight: 700,
            },
            '.MuiTab-root': {
              color: 'var(--black)',
            },
          },
          '&.light': {
            padding: 8,
            background: 'var(--white)',
            boxShadow: 'var(--header-drop)',
            borderRadius: 5,
            '.Mui-selected': {
              background: { xs: 'var(--white)' },
              fontWeight: 600,
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          height: 20,
          fontWeight: 600,
          borderRadius: 10,
          fontSize: 12,
          ...(ownerState.color === 'success' && {
            color: 'var(--success)',
            background: 'var(--success_25)',
            '.MuiSvgIcon-root': {
              color: 'var(--success)',
            },
          }),
          ...(ownerState.color === 'default' && {
            color: 'var(--black_50)',
            background: 'var(--disabled_25)',
            '.MuiSvgIcon-root': {
              color: 'var(--black_50)',
            },
          }),
          ...(ownerState.color === 'error' && {
            color: 'var(--error)',
            background: 'var(--error_20)',
            '.MuiSvgIcon-root': {
              color: 'var(--error)',
            },
          }),
          ...(ownerState.color === 'warning' && {
            color: 'var(--warning)',
            background: 'var(--state_20)',
          }),
          ...(ownerState.color === 'info' && {
            color: 'var(--secondary_dark)',
            background: 'var(--secondary_light)',
          }),
          ...(ownerState.color === 'primary' && {
            color: 'var(--primary_main)',
            background: 'var(--primary_10)',
          }),
          ...(ownerState.color === 'secondary' && {
            color: 'var(--black)',
            background: 'var(--primary_10)',
            fontWeight: 400,
            fontSize: 14,
            height: 18,
            '.MuiSvgIcon-root': {
              color: 'var(--black_50)',
            },
            ' .MuiChip-deleteIcon': {
              margin: 0,
              ':hover': {
                color: 'var(--black_50)',
              },
            },
          }),
          '&.configStatus .MuiSvgIcon-root': {
            fontSize: 12,
          },
        }),
        icon: {
          fontSize: 8,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 16,
          background: 'var(--primary_dark)',
          opacity: 0.9,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          borderRadius: 3,
          padding: 10,
          lineHeight: 1.5,
          '&.action-tooltip': {
            padding: 5,
            fontSize: 12,
            marginTop: '0 !important',
            marginBottom: '5px !important',
          },
        },
        arrow: {
          ':before': {
            background: 'var(--primary_dark)',
          },
        },
      },
    },

    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          width: 40,
          alignItems: 'center',
        },
        track: {
          paddingInline: 0,
          opacity: 'unset',
          border: '2px solid var(--neut_dark_grey)',
          borderRadius: 40,
          backgroundColor: 'var(--white)',
          height: 20,
        },
        switchBase: {
          paddingInline: 0,
          color: 'var(--white)',
          '&.Mui-checked': {
            color: 'var(--white)',
          },
          '&.Mui-checked+.MuiSwitch-track': {
            opacity: 'unset',
            borderColor: 'var(--primary_main)',
          },
          '&.Mui-checked .MuiSwitch-thumb': {
            borderColor: 'var(--primary_main)',
          },
          '&.Mui-checked.Mui-disabled+.MuiSwitch-track': {
            background: 'var(--disabled)',
          },
          '&.Mui-disabled+.MuiSwitch-track': {
            opacity: 'unset',
            borderColor: 'var(--disabled)',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: 'var(--ivory_25)',
            borderColor: 'var(--disabled)',
          },
        },
        thumb: {
          border: '2px solid var(--neut_dark_grey)',
          borderRadius: 40,
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        maxWidth: 'lg',
      },
      styleOverrides: {
        paper: {
          padding: 32,
          boxShadow: 'var(--dropdown_shadow)',
          borderRadius: 8,
          '.custom-icon': {
            marginRight: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 10,
            minWidth: 40,
            height: 40,
            '&.alert': {
              color: 'var(--error)',
              backgroundColor: 'var(--error_10)',
            },
            '&.warning': {
              color: 'var(--warning)',
              backgroundColor: 'var(--warning_10)',
            },
            '&.success': {
              color: 'var(--success)',
              backgroundColor: 'var(--success_10)',
            },
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
          color: 'var(--black)',
          fontWeight: 600,
          fontSize: 20,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0,
          marginTop: 28,
          justifyContent: 'center',
          '&.right': {
            justifyContent: 'flex-end',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          padding: 0,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          marginTop: 8,
          fontSize: 16,
          color: 'var(--black)',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: 0,
          marginLeft: 4,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          background: 'var(--ivory_50)',
          border: '1px solid var(--disabled_25)',
          ':before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          minHeight: 24,
        },
        content: {
          margin: 0,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          paddingRight: '10px !important',
        },
        endAdornment: {
          right: '0 !important',
          position: 'relative',
          '.MuiIconButton-root': {
            padding: 2,
          },
        },
        popupIndicator: {
          borderRadius: 0,
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderColor: 'var(--secondary_dark)',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: 'var(--secondary_dark)',
          borderColor: 'var(--secondary_dark)',
          height: 32,
          textTransform: 'capitalize',
          '&.Mui-selected': {
            color: 'var(--white)',
            fontWeight: 700,
            backgroundColor: 'var(--secondary_dark)',
            '&:hover': {
              backgroundColor: 'var(--secondary_dark)',
            },
          },
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          padding: 0,
          '&.completed-step': {
            '.MuiStepLabel-root': {
              borderColor: 'var(--primary_10)',
            },
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: 'var(--primary_50)',
          '&.Mui-completed': {
            color: 'var(--primary_50)',
            borderColor: 'var(--primary_10)',
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          padding: 16,
          border: 1,
          borderStyle: 'solid',
          borderColor: 'var(--primary_main)',
          borderRadius: 8,
          '&.Mui-disabled': {
            borderColor: 'var(--primary_10)',
          },
        },
        label: {
          '&.Mui-active': {
            fontWeight: 400,
            color: 'var(--primary_main)',
          },
          '&.Mui-completed': {
            color: 'var(--primary_50)',
            borderColor: 'var(--primary_10)',
          },
          '&.Mui-disabled': {
            color: 'var(--primary_50)',
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
  },
});

export default theme;
