import { lazy } from 'react';
import { PAGES, ROUTES } from './constants';

// eslint-disable-next-line
const NoAuthLayout = lazy(() =>
  import('../layouts/no-auth').then((module) => ({
    default: module.NoAuthLayout,
  }))
);
const AuthLayout = lazy(() =>
  import('../layouts/auth').then((module) => ({
    default: module.AuthLayout,
  }))
);
const Dashboard = lazy(() =>
  import('../pages/dashboard').then((module) => ({
    default: module.default,
  }))
);

const CorporateLayout = lazy(() =>
  import('../pages/corporate').then((module) => ({
    default: module.default,
  }))
);

const Configuration = lazy(() =>
  import('../pages/configuration').then((module) => ({
    default: module.default,
  }))
);

const Alltimesheets = lazy(() =>
  import('../pages/timesheets').then((module) => ({
    default: module.default,
  }))
);

const TimesheetsQueue = lazy(() =>
  import('../pages/timesheets-queue').then((module) => ({
    default: module.default,
  }))
);

export const Payroll = lazy(() =>
  import('../pages/payroll').then((module) => ({
    default: module.default,
  }))
);

const Invoice = lazy(() =>
  import('../pages/invoice').then((module) => ({
    default: module.default,
  }))
);

const Reports = lazy(() =>
  import('../pages/reports').then((module) => ({
    default: module.default,
  }))
);

const HelpAndSupport = lazy(() =>
  import('../layouts/help-support').then((module) => ({
    default: module.default,
  }))
);

const PAGE_NOT_FOUND = lazy(() =>
  import('../layouts/pageNotFound').then((module) => ({
    default: module.default,
  }))
);

export const appRoutes = [
  // [ROUTES.AUTH + '/*', NoAuthLayout, Auth],
  [ROUTES.START, AuthLayout, Dashboard, PAGES.Dashboard],
  [ROUTES.DASHBOARD, AuthLayout, Dashboard, PAGES.Dashboard],
  [ROUTES.CONFIGURATION + '/*', AuthLayout, Configuration, PAGES.Configuration],
  [ROUTES.ALL_TIMESHEETS + '/*', AuthLayout, Alltimesheets, PAGES.AllTimesheets],
  [ROUTES.PAYROLL, AuthLayout, Payroll, PAGES.Payroll],
  [ROUTES.INVOICE, AuthLayout, Invoice, PAGES.Invoice],
  [ROUTES.REPORTS + '/*', AuthLayout, Reports, PAGES.EmbeddedReports],
  [ROUTES.CORPORATE_REPORTS + '/*', AuthLayout, Reports, PAGES.CorporateEmbeddedReports],
  [ROUTES.CORPORATE + '/*', AuthLayout, CorporateLayout, PAGES.CorporateDashboard],
  [ROUTES.SUPPORT, AuthLayout, HelpAndSupport],
  [
    ROUTES.BULK_ACTION_TIMESHEET_BATCHES + '/*',
    AuthLayout,
    TimesheetsQueue,
    PAGES.Bulk_Timesheet_Operations,
  ],
  ['*', AuthLayout, PAGE_NOT_FOUND],
];
