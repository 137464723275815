export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_CURRENT_MODAL = 'CLOSE_CURRENT_MODAL';
export const defaultState = { name: '', payload: {} };

export interface IModalState {
  name: string;
  payload: object;
  config: object;
}

export interface IState {
  modals: IModalState[];
}
export const getInitialState = () => ({
  modals: [],
});

const modalReducer = (state: IState = getInitialState(), action: any) => {
  switch (action.type) {
    case OPEN_MODAL: {
      const modals = state.modals;
      modals.push({
        name: action.payload.name,
        payload: action.payload?.payload,
        config: action.payload?.config,
      });
      return {
        ...state,
        modals,
      };
    }
    case CLOSE_CURRENT_MODAL:
      const modals = state.modals;
      modals.pop();
      return {
        ...state,
        modals,
      };
    default:
      return state;
  }
};

export default modalReducer;
