export const ROUTES = {
  START: '/',
  DASHBOARD: '/dashboard',
  CONFIGURATION: '/configuration',
  CLIENT: '/configuration/customers',
  CUSTOMER_INTEGRATION: '/configuration/customer_intgeration',
  ASSETS: '/configuration/assets',
  SYSTEM_CONFIG: '/configuration/systemConfig',
  ALL_TIMESHEETS: '/timesheets',
  TIMESHEET_VIEW: '/timesheets/view',
  PEOPLE_VIEW: '/people',
  SUMMARY_VIEW: '/summary',
  SUMMARY_CUSTOMER: '/summary/customer',
  SUMMARY_GENERAL_FOREMAN: '/summary/generalForeman',
  PAYROLL: '/payroll',
  INVOICE: '/invoice',
  REPORTS: '/reports',
  VIEW_REPORT: '/view/:id/:groupId/:reportId/:reportType',
  CORPORATE: '/corporate',
  CORPORATE_DASHBOARD: '/corporate/dashboard',
  CORPORATE_ALL_TIMESHEETS: '/corporate/timesheets',
  CORPORATE_PAYROLL: '/corporate/payroll',
  CORPORATE_REPORTS: '/corporate/reports',
  SUPPORT: '/support',
  SYSTEM_WORKWEEK_TEMPLATE: '/systemConfig/template',
  BULK_ACTION_TIMESHEET_BATCHES: '/timesheet-batches',
};

export const PAGES = {
  Dashboard: 'Dashboard',
  AllTimesheets: 'AllTimesheets',
  Configuration: 'Configuration',
  SystemConfig: 'SystemConfig',
  Customers: 'Customers',
  Assets: 'Assets',
  Invoice: 'Invoice',
  Payroll: 'Payroll',
  Reports: 'Reports',
  EmbeddedReports: 'EmbeddedReports',
  CorporateDashboard: 'CorporateDashboard',
  CorporateAllTimesheets: 'CorporateAllTimesheet',
  CorporatePayroll: 'CorporatePayroll',
  CorporateEmbeddedReports: 'CorporateEmbeddedReports',
  CustomerIntegration: 'CustomerIntegration',
  SystemConfig_Template: 'SystemConfig_Template',
  Bulk_Timesheet_Operations: 'Bulk_Timesheet_Operations',
};
