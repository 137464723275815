import moment from 'moment';
import {
  CONFIG_STATUS_SUCCESS,
  CUSTOMER_DROP_DOWN_SUCCESS,
  REMEMBER_GF_ALL_TIMESHEET_FILTER,
  REMEMBER_GF_INVOICE_TIMESHEET_FILTER,
  REMEMBER_CORPORATE_ALL_TIMESHEET_FILTER,
  SET_ISFETCHING,
  SET_ROLE_PERMISSIONS,
  SHIFT_TIME_TYPE_SUCCESS,
  SHIFT_TYPE_SUCCESS,
  TIMESHEET_DROP_DOWN_SUCCESS,
  SET_NOTIFICATIONS,
  SET_CONNECTION_STATUS,
  SET_RECENT_NOTIFICATION,
} from '../actions/common';
import {
  IFilterCorporateTimesheetTable,
  IFilterInvoiceTable,
  IFilterTimesheetTable,
} from '../../models';

export interface ICommonState {
  isFetching: boolean;
  rolePermissions: any;
  configStatuses: any[];
  shiftTypes: any[];
  customerDropDown: any[];
  timesheetDropDown: any[];
  shiftTimeType: any[];
  gfAllTimesheetFilters: any;
  gfInvoiceTimesheetFilters: any;
  corporateAllTimesheetFilters: any;
  notifications: any[];
  connectionStatus: boolean;
  recentNotification: any;
}
export const getInitialState = () => ({
  isFetching: false,
  rolePermissions: {},
  notifications: [],
  recentNotification: {},
  configStatuses: [],
  shiftTypes: [],
  customerDropDown: [],
  timesheetDropDown: [],
  shiftTimeType: [],
  gfAllTimesheetFilters: {
    customerIds: [],
    jobNumbers: [],
    startDate: moment().startOf('month').toDate(),
    endDate: moment().endOf('month').toDate(),
    pageSize: 30,
    currentPage: 1,
    generalForeman: [],
    shiftEmployeeNo: '',
    status: [],
    weekFilter: 2,
    division: '',
    timesheetNo: '',
    inActiveGF: false,
    employee: [],
  },
  gfInvoiceTimesheetFilters: {
    customerIds: [],
    jobNumbers: [],
    contractType: [],
    pageSize: 10,
    currentPage: 1,
    weekFilter: 2,
    startDate: moment().startOf('month').toDate(),
    endDate: moment().endOf('month').toDate(),
    division: '',
  },
  corporateAllTimesheetFilters: {
    startDate: moment().startOf('month').toDate(),
    endDate: moment().endOf('month').toDate(),
    pageSize: 30,
    currentPage: 1,
    weekFilter: 2,
    division: [],
    managers: [],
    employees: [],
    status: [],
    timesheetNo: '',
  },
  connectionStatus: false,
});

const commonReducer = (state: ICommonState = getInitialState(), action: any) => {
  switch (action.type) {
    case SET_ISFETCHING:
      return {
        ...state,
        isFetching: action.payload,
      };
    case SET_ROLE_PERMISSIONS:
      return {
        ...state,
        rolePermissions: action.payload,
      };
    case CONFIG_STATUS_SUCCESS:
      return {
        ...state,
        configStatuses: action.payload,
      };
    case SHIFT_TYPE_SUCCESS:
      return {
        ...state,
        shiftTypes: action.payload,
      };
    case CUSTOMER_DROP_DOWN_SUCCESS:
      return {
        ...state,
        customerDropDown: action.payload,
      };
    case TIMESHEET_DROP_DOWN_SUCCESS:
      return {
        ...state,
        timesheetDropDown: action.payload,
      };
    case SHIFT_TIME_TYPE_SUCCESS:
      return {
        ...state,
        shiftTimeType: action.payload,
      };
    case REMEMBER_GF_ALL_TIMESHEET_FILTER:
      return {
        ...state,
        gfAllTimesheetFilters: action.payload as IFilterTimesheetTable,
      };
    case REMEMBER_GF_INVOICE_TIMESHEET_FILTER:
      return {
        ...state,
        gfInvoiceTimesheetFilters: action.payload as IFilterInvoiceTable,
      };
    case REMEMBER_CORPORATE_ALL_TIMESHEET_FILTER:
      return {
        ...state,
        corporateAllTimesheetFilters: action.payload as IFilterCorporateTimesheetTable,
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case SET_CONNECTION_STATUS:
      return {
        ...state,
        connectionStatus: action.payload,
      };
    case SET_RECENT_NOTIFICATION:
      return {
        ...state,
        recentNotification: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
