import { store } from '../store';

export const SET_ISFETCHING = 'SET_ISFETCHING';
export const SET_ROLE_PERMISSIONS = 'SET_ROLE_PERMISSIONS';
export const CONFIG_STATUS_SUCCESS = 'CONFIG_STATUS_SUCCESS';
export const SHIFT_TYPE_SUCCESS = 'SHIFT_TYPE_SUCCESS';
export const CUSTOMER_DROP_DOWN_SUCCESS = 'CUSTOMER_DROP_DOWN_SUCCESS';
export const TIMESHEET_DROP_DOWN_SUCCESS = 'TIMESHEET_DROP_DOWN_SUCCESS';
export const SHIFT_TIME_TYPE_SUCCESS = 'SHIFT_TIME_TYPE_SUCCESS';
export const REMEMBER_GF_ALL_TIMESHEET_FILTER = 'REMEMBER_GF_ALL_TIMESHEET_FILTER';
export const REMEMBER_GF_INVOICE_TIMESHEET_FILTER = 'REMEMBER_GF_INVOICE_TIMESHEET_FILTER';
export const REMEMBER_CORPORATE_ALL_TIMESHEET_FILTER = 'REMEMBER_CORPORATE_ALL_TIMESHEET_FILTER';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_CONNECTION_STATUS = 'SET_CONNECTION_STATUS';
export const SET_RECENT_NOTIFICATION = 'SET_RECENT_NOTIFICATION';

export const startFetching = () => ({
  type: SET_ISFETCHING,
  payload: true,
});
export const stopFetching = () => ({
  type: SET_ISFETCHING,
  payload: false,
});

export const setRolePermissionsSuccess = (payload: any) => ({
  type: SET_ROLE_PERMISSIONS,
  payload,
});

export const GetConfigStatusSuccess = (payload: any) => ({
  type: CONFIG_STATUS_SUCCESS,
  payload,
});

export const GetShiftTypeSuccess = (payload: any) => ({
  type: SHIFT_TYPE_SUCCESS,
  payload,
});

export const GetCustomerDropDownSuccess = (payload: any) => ({
  type: CUSTOMER_DROP_DOWN_SUCCESS,
  payload,
});

export const GetTimeSheetDropDownSuccess = (payload: any) => ({
  type: TIMESHEET_DROP_DOWN_SUCCESS,
  payload,
});

export const GetShiftTimeTypeSuccess = (payload: any) => ({
  type: SHIFT_TIME_TYPE_SUCCESS,
  payload,
});

export const RememberGFAllTimesheetFilters = (payload: any) => {
  const user_id = store.getState().commonReducer.rolePermissions.id;
  sessionStorage.setItem(`gf_all_timesheet_filter_${user_id}`, JSON.stringify(payload));
  return {
    type: REMEMBER_GF_ALL_TIMESHEET_FILTER,
    payload,
  };
};

export const RememberGFInvoiceTimesheetFilters = (payload: any) => {
  const user_id = store.getState().commonReducer.rolePermissions.id;
  sessionStorage.setItem(`gf_invoice_timesheet_filter_${user_id}`, JSON.stringify(payload));
  return {
    type: REMEMBER_GF_INVOICE_TIMESHEET_FILTER,
    payload,
  };
};

export const RememberCorporateAllTimesheetFilters = (payload: any) => {
  const user_id = store.getState().commonReducer.rolePermissions.id;
  sessionStorage.setItem(`corporate_all_timesheet_filter_${user_id}`, JSON.stringify(payload));
  return {
    type: REMEMBER_CORPORATE_ALL_TIMESHEET_FILTER,
    payload,
  };
};

export const SetNotifications = (payload: any) => {
  const user_id = store.getState().commonReducer.rolePermissions.id;
  sessionStorage.setItem(`notifications_${user_id}`, JSON.stringify(payload));
  return {
    type: SET_NOTIFICATIONS,
    payload,
  };
};

export const SetConnectionStatus = (payload: any) => {
  return {
    type: SET_CONNECTION_STATUS,
    payload,
  };
};

export const SetRecentNotification = (payload: any) => {
  return {
    type: SET_RECENT_NOTIFICATION,
    payload,
  };
};
