import { Box, Typography } from '@mui/material';
import React from 'react';
export const NoModuleAccess = () => {
  return (
    <Box sx={{ m: 4 }} display={'flex'} alignItems={'center'} flexDirection={'column'}>
      <Typography color={'var(--primary_main)'} fontWeight={600} fontSize={32}>
        You do not have access to any module yet, please contact admin.
      </Typography>
      <img
        src={require('../assets/images/NoModuleAccess.png')}
        style={{ marginTop: 56 }}
        alt={'LTS'}
      />
    </Box>
  );
};

export default NoModuleAccess;
