export const modalNames = {
  TEST: 'TEST',
  CONFIRMATION_POPUP: 'CONFIRMATION_POPUP',
  ADD_EXISTING_USER: 'ADD_EXISTING_USER',
  ADD_OTHER_EMPLOYEE: 'ADD_OTHER_EMPLOYEE',
  ADD_OTHER_ASSET: 'ADD_OTHER_ASSET',
  MANAGE_TIMESHEET: 'MANAGE_TIMESHEET',
  SUCCESS_POPUP: 'SUCCESS_POPUP',
  MANAGE_APPOVE_REJECT_TIMESHEET: 'MANAGE_APPOVE_REJECT_TIMESHEET',
  SUBMIL_PAYROLL_MODAL: 'SUBMIL_PAYROLL_MODAL',
  POST_TO_JDE: 'POST_TO_JDE',
  MANAGE_FORCE_UPDATE: 'MANAGE_FORCE_UPDATE',
  ALERT_POPUP: 'ALERT_POPUP',
  MANAGE_CUSTOMER_ROLES: 'MANAGE_CUSTOMER_ROLES',
  INVOICE_ERROR: 'INVOICE_ERROR',
  INVOICE_WARNING: 'INVOICE_WARNING',
  VIEW_ALL_JOBS: 'VIEW_ALL_JOBS',
  PAYROLL_EXCEL_EXPORT: 'PAYROLL_EXCEL_EXPORT',
  VIEW_TIMESHEETS_MODAL: 'VIEW_TIMESHEETS_MODAL',
  MANAGE_CORPORATE_TIMESHEET: 'MANAGE_CORPORATE_TIMESHEET',
  MANAGE_CUSTOMER_INTEGRATION: 'MANAGE_CUSTOMER_INTEGRATION',
  SUCCESS_CUSTOMER_INTEGRATION: 'SUCCESS_CUSTOMER_INTEGRATION',
  VIEW_CORPORATE_TIMESHEETS_MODAL: 'VIEW_CORPORATE_TIMESHEETS_MODAL',
  MULTIPLE_CUSTOMER_ROLE_CONFIRMATION_MODAL: 'MULTIPLE_CUSTOMER_ROLE_CONFIRMATION_MODAL',
  MANAGE_CATEGORY: 'MANAGE_CATEGORY',
  ASSIGN_CUSTOMER: 'ASSIGN_CUSTOMER',
  ASSIGN_JOB: 'ASSIGN_JOB',
  VIEW_BATCH_TIMESHEET_MODAL: 'VIEW_BATCH_TIMESHEET_MODAL',
  VIEW_BULK_ACTION_TIMESHEETS_ERROR_MODAL: 'VIEW_BULK_ACTION_TIMESHEETS_ERROR_MODAL',
  VIEW_ATTACHMENTS_MODAL: 'VIEW_ATTACHMENTS_MODAL',
  BREAK_HOURS_MODAL: 'BREAK_HOURS_MODAL',
  MANAGE_BULK_IMPORT_DATA: 'MANAGE_BULK_IMPORT_DATA',
  VIEW_EXISTING_DATA_POINTS: 'VIEW_EXISTING_DATA_POINTS',
  MANAGE_BULK_USER_ACCESS: 'MANAGE_BULK_USER_ACCESS',
  VIEW_SELECTED_JOBS: 'VIEW_SELECTED_JOBS',
  VIEW_SELECTED_USERS: 'VIEW_SELECTED_USERS',
};

export const ROLES = {
  PLATFORM_ADMIN: 'PlatformAdmin',
  GENERAL_FOREMAN: 'GeneralForeman',
  CREW_LEADER: 'CrewLeader',
  GUEST: 'Guest',
  CMS: 'CMS',
  FIELD_USER: 'FieldUser',
  PAYROLL: 'Payroll',
  SUPERVISOR: 'Supervisor',
  CORPORATE_USER: 'CorporateUser',
  CORPORATE_MANAGER: 'CorporateManager',
  OPERATIONAL_ADMIN: 'OperationalAdmin',
};

export const configStatus = {
  Closed: 'default',
  New: 'primary',
  Configuration: 'warning',
  Live: 'success',
  Onhold: 'info',
} as const;

export enum JobConfigStatus {
  Closed = 'Closed',
  New = 'New',
  Configuration = 'Configuration',
  Live = 'Live',
  Onhold = 'Onhold',
}

export type IConfigStatus = keyof typeof configStatus;
export type IConfigStatusValues = typeof configStatus[IConfigStatus];

export const jobStatus = {
  Inactive: 'default',
  New: 'default',
  Active: 'success',
  Closed: 'warning',
} as const;
export type IJobStatus = keyof typeof jobStatus;
export type IJobStatusValues = typeof jobStatus[IJobStatus];
