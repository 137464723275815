import { combineReducers } from 'redux';
import modalReducer from '../../components/modals/modal-reducer';
import systemConfigReducer from './systemConfig';
import configurationReducer from './configuration';
import commonReducer from './common';
import timesheetReducer from './timesheet';
import corporateReducer from './corporate';

const rootReducer = combineReducers({
  systemConfigReducer,
  modalReducer,
  commonReducer,
  configurationReducer,
  timesheetReducer,
  corporateReducer,
});
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
