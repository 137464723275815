import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import PageNotAccessible from '../layouts/pageNotAccessible';
import { RootState } from '../redux/reducers';
import { PAGES, ROUTES } from './constants';
import { Payroll, appRoutes } from './route-configs';
import { ROLES } from '../components/modals/constants';
import NoModuleAccess from '../layouts/noModuleAccess';

const renderRoutePage = (Layout: any, Component: any, props: any) => (
  <Layout>
    <Suspense fallback={true}>
      <Component {...props} />
    </Suspense>
  </Layout>
);

export const AppRoutes = () => {
  const {
    commonReducer: {
      rolePermissions: {
        modulePermissions,
        roleName,
        ukgEmployeeNo,
        isCorporateManager,
        isCorporateUser,
      },
      isFetching,
    },
  } = useSelector((state: RootState) => state);
  return (
    <>
      <Routes>
        {appRoutes.map(([routeName, Layout, Component, Page]) => {
          if (roleName === ROLES.PAYROLL && routeName === ROUTES.START) {
            Component = Payroll;
            Page = PAGES.Payroll;
          }
          // eslint-disable-next-line
          const view =
            Page === undefined ||
            (modulePermissions &&
              (modulePermissions as any)[Page as keyof typeof PAGES] &&
              (modulePermissions as any)[Page as keyof typeof PAGES].CanRead);
          // Condition to access pages as per user roles
          const hasNoModuleAccess =
            ([ROLES.GENERAL_FOREMAN, ROLES.CREW_LEADER, ROLES.SUPERVISOR].includes(roleName) ||
              isCorporateManager ||
              isCorporateUser) &&
            !ukgEmployeeNo;
          return (
            <Route
              key={routeName as string}
              path={routeName as string}
              element={
                view
                  ? renderRoutePage(Layout, Component, null)
                  : renderRoutePage(
                      Layout,
                      !isFetching
                        ? hasNoModuleAccess
                          ? NoModuleAccess
                          : PageNotAccessible
                        : () => <></>,
                      null
                    )
              }
            />
          );
        })}
      </Routes>
    </>
  );
};
