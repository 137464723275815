export const SHOW_CORPORATE = 'SHOW_CORPORATE';
export const SET_CORPORATE_TIMESHEET_STATUSES = 'SET_CORPORATE_TIMESHEET_STATUSES';
export const SET_CORPORATE_EMPLOYEES = 'SET_CORPORATE_EMPLOYEES';

export const setCorporate = (payload: any) => ({
  type: SHOW_CORPORATE,
  payload,
});

export const setCorporateTimesheetStatuses = (payload: any) => ({
  type: SET_CORPORATE_TIMESHEET_STATUSES,
  payload,
});

export const setCorporateEmployees = (payload: any) => ({
  type: SET_CORPORATE_EMPLOYEES,
  payload,
});
