import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { b2cmsalConfig } from './auth/b2cLogin';
import './assets/fonts/Assistant-Regular.woff';
import './assets/fonts/Assistant-SemiBold.woff';
import './assets/fonts/Assistant-Bold.woff';
import './assets/fonts/Merriweather-Bold.woff';
export const msalInstance = new PublicClientApplication(b2cmsalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <App />
    </Provider>
  </MsalProvider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
