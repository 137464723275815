import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import React, { Fragment, Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './routes/routes';
import { Spinner } from './components/spinner';
import theme from './theme';
import translations from './locale/i18n';
import './App.scss';

const App = () => {
  useEffect(() => {
    translations.setup();
  }, []);

  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Suspense fallback={<Spinner />}>
            <Fragment>
              <main>
                <AppRoutes />
              </main>
            </Fragment>
          </Suspense>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
};

export default App;
