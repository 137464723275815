import {
  SET_CORPORATE_EMPLOYEES,
  SET_CORPORATE_TIMESHEET_STATUSES,
  SHOW_CORPORATE,
} from '../actions/corporate';

export interface ICorporateState {
  isCorporate: boolean;
  timesheetStatuses: any[];
  corporateEmployees: any[];
}
export const getInitialState = () => ({
  isCorporate: false,
  timesheetStatuses: [],
  corporateEmployees: [],
});

const corporateReducer = (state: ICorporateState = getInitialState(), action: any) => {
  switch (action.type) {
    case SHOW_CORPORATE:
      return {
        ...state,
        isCorporate: action.payload,
      };
    case SET_CORPORATE_TIMESHEET_STATUSES:
      return {
        ...state,
        timesheetStatuses: action.payload,
      };
    case SET_CORPORATE_EMPLOYEES:
      return {
        ...state,
        corporateEmployees: action.payload,
      };
    default:
      return state;
  }
};

export default corporateReducer;
