import { BrowserCacheLocation } from '@azure/msal-browser';

const {
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_SIGN_IN_POLICY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_AUTH_INSTANCE,
} = process.env;
export const REDIRECT_URL = '/';

export const b2cmsalConfig = {
  auth: {
    clientId: `${REACT_APP_AUTH_CLIENT_ID}`,
    authority: `${REACT_APP_AUTH_INSTANCE}/${REACT_APP_AUTH_DOMAIN}/${REACT_APP_SIGN_IN_POLICY}`,
    knownAuthorities: [
      `${REACT_APP_AUTH_INSTANCE?.substring(REACT_APP_AUTH_INSTANCE.indexOf('//') + 2)}`,
    ],
    redirectUri: `${window.location.origin}${REDIRECT_URL}`,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: ['openid'],
};
