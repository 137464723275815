import { ICompanies, IDropdownOptions, ILocationType, IRole, IUser } from '../../models';
import {
  COMPANIES_SUCCESS,
  CREW_REFERENCES_SUCCESS,
  HR_ROLE_SUCCESS,
  LOCATION_TYPES_SUCCESS,
  OTHER_BILLABLES_SUCCESS,
  ROLES_SUCCESS,
  SYSTEM_WORK_WEEK_TEMPLATES_SUCCESS,
  TEMPLATE_CATEGORY_SUCCESS,
  UKG_EMPLOYEE_SUCCESS,
  UKG_FAMILY_ROLE_SUCCESS,
  USERS_SUCCESS,
  ADDITIONAL_INFO_SUCCESS,
} from '../actions/systemConfig';

export interface IAuthState {
  roles: IRole[];
  users: IUser[];
  locationTypes: ILocationType[];
  companies: ICompanies[];
  hrRoles: any[];
  ukgFamilyRoles: IDropdownOptions[];
  otherBillables: any[];
  ukgEmployees: IDropdownOptions[];
  crewReferences: IDropdownOptions[];
  templateCategories: any[];
  systemWorkWeekTemplates: any[];
  additionalInfos: any[];
}
export const getInitialState = () => ({
  roles: [],
  users: [],
  locationTypes: [],
  companies: [],
  hrRoles: [],
  ukgFamilyRoles: [],
  otherBillables: [],
  ukgEmployees: [],
  crewReferences: [],
  templateCategories: [],
  systemWorkWeekTemplates: [],
  additionalInfos: [],
});

const systemConfigReducer = (state: IAuthState = getInitialState(), action: any) => {
  switch (action.type) {
    case ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
      };
    case USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };
    case LOCATION_TYPES_SUCCESS:
      return {
        ...state,
        locationTypes: action.payload,
      };
    case COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload,
      };
    case HR_ROLE_SUCCESS:
      return {
        ...state,
        hrRoles: action.payload,
      };
    case UKG_FAMILY_ROLE_SUCCESS:
      return {
        ...state,
        ukgFamilyRoles: action.payload,
      };
    case OTHER_BILLABLES_SUCCESS:
      return {
        ...state,
        otherBillables: action.payload,
      };
    case UKG_EMPLOYEE_SUCCESS:
      return {
        ...state,
        ukgEmployees: action.payload,
      };
    case CREW_REFERENCES_SUCCESS:
      return {
        ...state,
        crewReferences: action.payload,
      };
    case TEMPLATE_CATEGORY_SUCCESS:
      return {
        ...state,
        templateCategories: action.payload,
      };
    case SYSTEM_WORK_WEEK_TEMPLATES_SUCCESS:
      return {
        ...state,
        systemWorkWeekTemplates: action.payload,
      };
    case ADDITIONAL_INFO_SUCCESS:
      return {
        ...state,
        additionalInfos: action.payload,
      };
    default:
      return state;
  }
};

export default systemConfigReducer;
