export const ROLES_SUCCESS = 'ROLES_SUCCESS';
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const LOCATION_TYPES_SUCCESS = 'LOCATION_TYPES_SUCCESS';
export const COMPANIES_SUCCESS = 'COMPANIES_SUCCESS';
export const HR_ROLE_SUCCESS = 'HR_ROLE_SUCCESS';
export const UKG_FAMILY_ROLE_SUCCESS = 'UKG_FAMILY_ROLE_SUCCESS';
export const OTHER_BILLABLES_SUCCESS = 'OTHER_BILLABLES_SUCCESS';
export const UKG_EMPLOYEE_SUCCESS = 'UKG_EMPLOYEE_SUCCESS';
export const CREW_REFERENCES_SUCCESS = 'CREW_REFERENCES_SUCCESS';
export const TEMPLATE_CATEGORY_SUCCESS = 'TEMPLATE_CATEGORY_SUCCESS';
export const SYSTEM_WORK_WEEK_TEMPLATES_SUCCESS = 'SYSTEM_WORK_WEEK_TEMPLATES_SUCCESS';
export const ADDITIONAL_INFO_SUCCESS = 'ADDITIONAL_INFO_SUCCESS';

export const GetRolesSuccess = (payload: any) => ({
  type: ROLES_SUCCESS,
  payload,
});

export const GetUsersSuccess = (payload: any) => ({
  type: USERS_SUCCESS,
  payload,
});

export const GetLocationTypesSuccess = (payload: any) => ({
  type: LOCATION_TYPES_SUCCESS,
  payload,
});

export const GetCompaniesSuccess = (payload: any) => ({
  type: COMPANIES_SUCCESS,
  payload,
});

export const GetUkgFamilyRolesSuccess = (payload: any) => ({
  type: UKG_FAMILY_ROLE_SUCCESS,
  payload,
});

export const GetHrRoleSuccess = (payload: any) => ({
  type: HR_ROLE_SUCCESS,
  payload,
});

export const GetOtherBillableSuccess = (payload: any) => ({
  type: OTHER_BILLABLES_SUCCESS,
  payload,
});

export const GetUkgEmployeesSuccess = (payload: any) => ({
  type: UKG_EMPLOYEE_SUCCESS,
  payload,
});

export const GetAllCrewReferencesSuccess = (payload: any) => ({
  type: CREW_REFERENCES_SUCCESS,
  payload,
});

export const SetTemplateCategoriesSuccess = (payload: any) => ({
  type: TEMPLATE_CATEGORY_SUCCESS,
  payload,
});

export const SetSystemLevelWorWeekTemplatesSuccess = (payload: any) => ({
  type: SYSTEM_WORK_WEEK_TEMPLATES_SUCCESS,
  payload,
});

export const GetAdditionalInfoSuccess = (payload: any) => ({
  type: ADDITIONAL_INFO_SUCCESS,
  payload,
});
