import { IClient, IDropdownOptions, IJobAccess } from '../../models';
import {
  CLIENTS_SUCCESS,
  CONTRACT_TYPES,
  JOB_ACCESS_SUCCESS,
  JOB_DIVISIONS,
  PAY_RATE_SUCCESS,
  WORK_WEEK_PAY_RATE_SUCCESS,
} from '../actions/configuration';

export interface IAuthState {
  clients: IClient[];
  payRates: IDropdownOptions[];
  jobAccess: IJobAccess[];
  workWeekPayRates: IDropdownOptions[];
  contractTypes: IDropdownOptions[];
  jobsDivisions: IDropdownOptions[];
}
export const getInitialState = () => ({
  clients: [],
  payRates: [],
  jobAccess: [],
  workWeekPayRates: [],
  contractTypes: [],
  jobsDivisions: [],
});

const configurationReducer = (state: IAuthState = getInitialState(), action: any) => {
  switch (action.type) {
    case CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload,
      };
    case PAY_RATE_SUCCESS:
      return {
        ...state,
        payRates: action.payload,
      };
    case JOB_ACCESS_SUCCESS:
      return {
        ...state,
        jobAccess: action.payload,
      };
    case WORK_WEEK_PAY_RATE_SUCCESS:
      return {
        ...state,
        workWeekPayRates: action.payload,
      };
    case CONTRACT_TYPES:
      return {
        ...state,
        contractTypes: action.payload,
      };
    case JOB_DIVISIONS:
      return {
        ...state,
        jobsDivisions: action.payload,
      };
    default:
      return state;
  }
};

export default configurationReducer;
