import { Backdrop } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    backdrop: {
      zIndex: 2000,
      color: '#fff',
    },
  })
);

export const Spinner = ({ ...props }) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={true} {...props}>
      <CircularProgress sx={{ color: 'var(--primary_main)' }} thickness={4} />
    </Backdrop>
  );
};
