import i18n from 'i18next';
import { initReactI18next, useTranslation, Trans } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import translationEN from './en.json';
import translationES from './es.json';
import 'moment/locale/es'; // without this line it didn't work

const mapHTML = (translations: any) => {
  // It is possible to map things like <bold> to <strong> in i18n <Trans> components.
  // But we can't control that use inside FormIO, whereas we can allow some HTML to be output.
  // string.replaceAll is available in all supported browsed, but not in Node (where our tests run!)
  const substitutions = [
    [/<bold>/g, '<strong>'],
    [/<\/bold>/g, '</strong>'],
  ];

  const substitute = (value: any) =>
    substitutions.reduce((acc, [s, r]) => acc.replace(s, r), value);

  return Object.fromEntries(
    Object.entries(translations).map(([key, value]) => [key, substitute(value)])
  );
};

const defaultResources = () => {
  return {
    en: { translation: mapHTML({ ...translationEN }) },
    es: { translation: mapHTML({ ...translationES }) },
  };
};

const currentResources = () => {
  return i18n.isInitialized ? i18n.options.resources : null;
};

const _determineResources = (specifiedResources: any) => {
  return specifiedResources || currentResources() || defaultResources();
};

const _shouldInitialise = (resources: any, i18n: any) => {
  return JSON.stringify(resources) !== JSON.stringify(i18n.options.resources);
};

const setLanguage = (lng: any) => {
  i18n.changeLanguage(lng);
  // moment.locale(lng);
  moment.updateLocale(lng, {
    week: {
      dow: 0, // set the first day of the week to Sunday (0)
    },
  });
};

export const getCurrentLanguage = () => {
  // return i18n.language || localStorage.i18nextLng;
  const language = i18n.language || localStorage.i18nextLng;
  const languageMap: { [key: string]: string } = {
    en: 'en-US',
    es: 'es-ES',
  };
  return languageMap[language] || language;
};

const setup = (specifiedResources?: any) => {
  const resources = _determineResources(specifiedResources);
  if (_shouldInitialise(resources, i18n)) {
    i18n
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        resources,
        fallbackLng: 'en',
        debug: false,

        ns: ['translation'],
        defaultNS: 'translation',
        keySeparator: false,
        interpolation: {
          escapeValue: false,
        },
      });

    const lng = getCurrentLanguage();
    setLanguage(lng);
  }

  return { useTranslation, Trans };
};

const exports = {
  setup,
  currentResources,
  getCurrentLanguage,
  setLanguage,
};
export default exports;
