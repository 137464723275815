import { Box, Typography } from '@mui/material';
import React from 'react';
export const PageNotAccessible = () => {
  return (
    <Box sx={{ m: 4 }} display={'flex'} alignItems={'center'} flexDirection={'column'}>
      <Typography color={'var(--state_error)'} fontWeight={600} fontSize={48}>
        Sorry! There is no authorization for you.
      </Typography>
      <img
        src={require('../assets/images/PageNotAccessible.png')}
        style={{ marginTop: 56 }}
        alt={'LTS'}
      />
    </Box>
  );
};

export default PageNotAccessible;
