import { SET_WEEK } from '../actions/timesheet';

export interface ITimesheetState {
  week: {
    start: string;
    end: string;
    number: number;
  } | null;
}
export const getInitialState = () => ({
  week: null,
});

const timesheetReducer = (state: ITimesheetState = getInitialState(), action: any) => {
  switch (action.type) {
    case SET_WEEK:
      return {
        ...state,
        week: action.payload,
      };
    default:
      return state;
  }
};

export default timesheetReducer;
